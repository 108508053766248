
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import SearchList from "@/ComponentsSystem/Production-history/SearchList.vue";
import Data from "@/data/Production-history/Data.json";
import Info from "@/data/Production-history/Info.json";

export default defineComponent({
  name: "overview",
  components: { SearchList },
  data(): {
    DetailType: string;
    DetailNumber: string;
    Data: any;
    Info: any;
    Type: string;
  } {
    return {
      DetailType: "",
      DetailNumber: "",
      Data: Data,
      Info: Info,
      Type: "",
    };
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("生產履歷詳情", ["生產履歷溝通數位化系統"]);
    });
    return {};
  },
});
