
import { defineComponent } from "vue";
import Data from "@/data/Production-history/Search.json";

export default defineComponent({
  name: "kt-widget-12",
  components: {},
  props: {
    widgetClasses: String,
  },
  data(): {
    Data: any;
  } {
    return {
      Data: Data,
    };
  },
  setup() {
    return {};
  },
});
